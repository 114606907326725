/* eslint-disable import/no-cycle */
/* eslint-disable sonarjs/cognitive-complexity */
import {
  Alignments,
  ButtonWidthType,
  ContentJustification,
  GridType,
  HoverType,
  IGalleryStyleParams,
  ImageCarouselArrowSize,
  ImageModeId,
  ImagePlacements,
  ImagePositions,
  ImageRatioId,
  LoadMoreType,
  PaginationType,
  RibbonPlacementId,
  RibbonType,
  VerticalAlignments,
} from '../types/galleryTypes';
import {DEFAULT_AUTO_GRID_PRODUCTS_COUNT, DEFAULT_PRODUCT_IMAGE_SIZE, MAX_ROWS} from '../constants';
import {AddToCartActionOption} from '@wix/wixstores-client-core/dist/es/src/constants';
import _ from 'lodash';

function getDefaultColumnsNumber(dimensions) {
  if (!dimensions) {
    return;
  }

  if (typeof dimensions.width !== 'number') {
    return 4;
  }
  const componentWidth: number = dimensions.width;
  const PRODUCT_ITEM_MIN_WIDTH: number = 250;
  const defaultColumns = Math.round(componentWidth / PRODUCT_ITEM_MIN_WIDTH);
  return Math.min(defaultColumns, 4);
}

function getDefaultRowsNumber(columnsNumber: number): number {
  if (!columnsNumber) {
    return;
  }
  const LEGACY_MAX_ITEM_PER_PAGE = 20;
  return Math.min(Math.floor(LEGACY_MAX_ITEM_PER_PAGE / columnsNumber), MAX_ROWS / 2);
}

export const getDefaultStyleParams = ({
  showAlternativeImage,
  galleryColumns,
  galleryRows,
  dimensions,
  isEditorX,
  galleryMargin,
  galleryMarginRow,
  galleryMarginColumn,
  gallery_gapSize,
  gallery_gapSizeColumn,
  gallery_gapSizeRow,
  gallery_titleFontStyle,
  gallery_priceFontStyle,
  gallery_addToCartButtonTextFont,
  gallery_ribbonTextFont,
  gallery_imageWidth,
  gallery_imageAndInfoSpacing,
  gallery_imageMode,
  galleryImageRatio,
  gallery_productMargin,
}): Partial<IGalleryStyleParams> => {
  const nextGalleryColumns = getDefaultColumnsNumber(dimensions);
  const nextGalleryRows = getDefaultRowsNumber(galleryColumns || nextGalleryColumns);
  const initRowColumnMarginsFromGalleryMargin =
    galleryMargin !== undefined && (galleryMarginRow === undefined || galleryMarginColumn === undefined);
  const initRowColumnGapsFromGalleryGapSize =
    gallery_gapSize !== undefined && (gallery_gapSizeRow === undefined || gallery_gapSizeColumn === undefined);
  const defaultMobileProductNameFontSize = gallery_titleFontStyle?.size || 16;
  const defaultMobileProductPriceFontSize = gallery_priceFontStyle?.size || 13;
  const defaultMobileButtonTextFontSize = gallery_addToCartButtonTextFont?.size || 15;
  const defaultMobileRibbonTextFontSize = gallery_ribbonTextFont?.size || 14;
  const defaultImageWidth = 50;
  const defaultImageAndInfoSpacing = 0;
  const defaultImageMode = ImageModeId.Crop;
  const defaultImageRatio = ImageRatioId._1x1;
  const defaultImageCarouselArrowSize = ImageCarouselArrowSize.SMALL;

  return {
    booleans: {
      is_gallery_rows_and_cols_were_modified: !_.isUndefined(galleryColumns) && !_.isUndefined(galleryRows),
      full_width: false,
      galleryFiltersCategories: true,
      galleryFiltersPrice: true,
      galleryFiltersProductOptions: true,
      galleryShowFilters: false,
      galleryShowSort: false,
      gallerySortNameAsc: true,
      gallerySortNameDes: true,
      gallerySortNewest: true,
      gallerySortPriceAsc: true,
      gallerySortPriceDes: true,
      gallery_showAddToCartButton: false,
      gallery_addToCartButtonShowOnHover: false,
      gallery_alternateImagePosition: false,
      gallery_showAddToCartQuantity: false,
      gallery_showDividers: false,
      gallery_showPrice: true,
      gallery_showRibbon: true,
      gallery_showProductName: true,
      gallery_showTitle: undefined,
      gallery_paginationFirstLastArrows: false,
      gallery_showProductOptionsButton: false,
      showQuickView: true,
      responsive: false,
    },
    numbers: {
      galleryColumns: nextGalleryColumns,
      galleryRows: nextGalleryRows,
      galleryMargin: 10,
      galleryMarginRow: initRowColumnMarginsFromGalleryMargin ? galleryMargin : 10,
      galleryMarginColumn: initRowColumnMarginsFromGalleryMargin ? galleryMargin : 10,
      'mobile:galleryMarginRow': 20,
      'mobile:galleryMarginColumn': 20,
      gallery_imageMode: defaultImageMode,
      'mobile:gallery_imageMode': gallery_imageMode || defaultImageMode,
      gallery_ribbonPlacement: RibbonPlacementId.OnImage,
      gallery_ribbonType: RibbonType.RECTANGLE,
      galleryImageRatio: defaultImageRatio,
      'mobile:gallery_productMargin': gallery_productMargin || 0,
      'mobile:galleryImageRatio': galleryImageRatio || defaultImageRatio,
      'mobile:galleryMargin': 10,
      'mobile:galleryColumns': 1,
      gallery_addToCartAction: AddToCartActionOption.MINI_CART,
      gallery_productSize: DEFAULT_PRODUCT_IMAGE_SIZE,
      gallery_productsCount: DEFAULT_AUTO_GRID_PRODUCTS_COUNT,
      gallery_gapSize: 20,
      gallery_gapSizeColumn: initRowColumnGapsFromGalleryGapSize ? gallery_gapSize : 20,
      gallery_gapSizeRow: initRowColumnGapsFromGalleryGapSize ? gallery_gapSize : 20,
      gallery_gridType: isEditorX ? GridType.AUTO : GridType.MANUAL,
      gallery_loadMoreProductsType: LoadMoreType.BUTTON,
      gallery_paginationFormat: PaginationType.PAGES,
      'mobile:gallery_productNameFontSize': defaultMobileProductNameFontSize,
      'mobile:gallery_productPriceFontSize': defaultMobileProductPriceFontSize,
      'mobile:gallery_buttonTextFontSize': defaultMobileButtonTextFontSize,
      'mobile:gallery_ribbonTextFontSize': defaultMobileRibbonTextFontSize,
      gallery_imageWidth: defaultImageWidth,
      'mobile:gallery_imageWidth': gallery_imageWidth || defaultImageWidth,
      gallery_imageAndInfoSpacing: defaultImageAndInfoSpacing,
      'mobile:gallery_imageAndInfoSpacing': gallery_imageAndInfoSpacing || defaultImageAndInfoSpacing,
    },
    fonts: {
      gallery_hoverType: {
        fontStyleParam: false,
        value: showAlternativeImage ? HoverType.Alternate : HoverType.None,
      },
      gallery_imageCarouselArrowSize: {
        fontStyleParam: false,
        value: defaultImageCarouselArrowSize,
      },
      gallery_paginationAlignment: {fontStyleParam: false, value: ContentJustification.CENTER},
      gallery_verticalAlignment: {fontStyleParam: false, value: VerticalAlignments.CENTER},
      gallery_imagePlacement: {fontStyleParam: false, value: ImagePlacements.VERTICAL},
      gallery_imagePosition: {fontStyleParam: false, value: ImagePositions.LEFT},
      gallery_alignment: {fontStyleParam: false, value: Alignments.LEFT},
      gallery_addToCartButtonWidth: {fontStyleParam: false, value: ButtonWidthType.STRETCH},
    },
  };
};
